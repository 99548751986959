

/*====================================
	1. Color
====================================*/
$COLOR_MAIN:    #001976; //メイン ロゴの色。メインで使う
$COLOR_SUB:     #E5EAFF; //アクセント 薄い背景
$COLOR_RED:     #DF1700; //アクセント 強調表示 赤
$COLOR_ORANGE:  #FF8C00; //アクセント 強調表示 オレンジ
$COLOR_YELLOW:  #FFDD33; //アクセント 強調表示 黄
$COLOR_BROWN:   #765D00; //アクセント 補色 茶色
$COLOR_RADIKO:  #00A7E9; //radiko ラジコ関連（通常）で使う。 水色
$COLOR_TIMEFREE:#E73C64; //radiko ラジコ関連（タイムフリー）で使う。 赤色
$COLOR_TWITTER: #1DA1F2; //twitter 水色

$GRAY_000: #FFFFFF;
$GRAY_100: #F5F5F5;
$GRAY_200: #E6E6E6;
$GRAY_300: #DDDDDD;
$GRAY_400: #AAAAAA;
$GRAY_500: #8D8D8D;
$GRAY_600: #666666;
$GRAY_700: #121212;
$GRAY_800: #000000;

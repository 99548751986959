@import "C:/Users/tanaka-k/Documents/fm-yokohama2022.git/resource/sass/module/__mixin.scss";
@import "C:/Users/tanaka-k/Documents/fm-yokohama2022.git/resource/sass/module/__variable.scss";
.page-node-type-info {
  .article {
    max-width: 1000px;
    margin: 20px auto 80px;
    .article-share {
      display: flex;
      justify-content: flex-end;
      margin: 0 0 4px;
    }
    .article-title {
      background: $COLOR_MAIN;
      color: $GRAY_000;
      font-size: clamp(20px,3vw,28px);
      font-weight: 700;
      line-height: 1.5;
      padding: 8px 12px;
    }
    .article-area {
      padding: 12px 12px 40px;
    }
    .lower-btn {
      text-align: center;
      .gotolist {
        display: inline-block;
        a {
          font-size: clamp(16px,3vw,20px);
          letter-spacing: 0.05em;
          display: flex;
          background: $COLOR_MAIN;
          color: $GRAY_000;
          padding: 16px 48px;
          line-height: 1;
          transform: translateX(0);
          box-shadow: 8px 8px 0 rgba(0, 25, 118, 0.5);
          transition: .3s;
          &:hover {
            transform: translateX(8px);
            box-shadow: 0 0 0 rgba(255, 255, 255, 0.5);
            transition: .3s;
          }
          position: relative;
          &::after {
            content: "";
            display: block;
            width: 21px;
            height: 10px;
            border-right: 3px solid $GRAY_000;
            border-bottom: 2.5px solid $GRAY_000;
            transform: skew(45deg, 0);
            margin-top: 8px;
            margin-left: 16px;
          }
        }
      }
    }
  }
}





/****************************************************
 PC scss
****************************************************/
@media screen and (min-width:768px) {
  .page-node-type-info {
    .article {
      .lower-btn {
        .gotolist {
          a {
            padding: 24px 64px;
          }
        }
      }
    }
  }
}

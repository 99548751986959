/*====================================
	1. Color
====================================*/
.page-node-type-info .article {
  max-width: 1000px;
  margin: 20px auto 80px;
}

.page-node-type-info .article .article-share {
  display: flex;
  justify-content: flex-end;
  margin: 0 0 4px;
}

.page-node-type-info .article .article-title {
  background: #001976;
  color: #FFFFFF;
  font-size: clamp(20px, 3vw, 28px);
  font-weight: 700;
  line-height: 1.5;
  padding: 8px 12px;
}

.page-node-type-info .article .article-area {
  padding: 12px 12px 40px;
}

.page-node-type-info .article .lower-btn {
  text-align: center;
}

.page-node-type-info .article .lower-btn .gotolist {
  display: inline-block;
}

.page-node-type-info .article .lower-btn .gotolist a {
  font-size: clamp(16px, 3vw, 20px);
  letter-spacing: 0.05em;
  display: flex;
  background: #001976;
  color: #FFFFFF;
  padding: 16px 48px;
  line-height: 1;
  transform: translateX(0);
  box-shadow: 8px 8px 0 rgba(0, 25, 118, 0.5);
  transition: .3s;
  position: relative;
}

.page-node-type-info .article .lower-btn .gotolist a:hover {
  transform: translateX(8px);
  box-shadow: 0 0 0 rgba(255, 255, 255, 0.5);
  transition: .3s;
}

.page-node-type-info .article .lower-btn .gotolist a::after {
  content: "";
  display: block;
  width: 21px;
  height: 10px;
  border-right: 3px solid #FFFFFF;
  border-bottom: 2.5px solid #FFFFFF;
  transform: skew(45deg, 0);
  margin-top: 8px;
  margin-left: 16px;
}

/****************************************************
 PC scss
****************************************************/
@media screen and (min-width: 768px) {
  .page-node-type-info .article .lower-btn .gotolist a {
    padding: 24px 64px;
  }
}
